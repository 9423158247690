<template>
    <div class="reset-wrap">
        <!-- /home?userId=1765&system_type=1 -->
        <div class="reset-content">
            <user-form 
                :formData="formData" 
                :formItems="formItems" 
                :formRules="formRules" 
                :formMsg="formMsg"
                :isLabel="false"
                @handleMsg="handleMsg"
                @onSubmit="onSubmit"
                butText="登录"
            >
            </user-form>
        </div>
        <div class="ministry">
            <div>Copyright <div class="lc">c</div> 2019-2020 wocyun.com. All Rights Reserved</div>
            <div>正悦公司 版权所有 <p @click="lookMes">蜀ICP备2021007831号-3</p> </div>
        </div>
    </div>
</template>

<script>
    import { validateElePhone, validateCode, validaMobile } from '@/utils/valida'
    import { setStorage } from '@/utils/common'
    import userForm from '@/components/userForm'
    export default {
        components: {
            userForm
        },
        data() {
            return {
                formData: {
                    mobile: '',
                    password: '',
                },
                formItems: [
                    {
                        type: 'input',
                        placeholder: '请输入手机号',
                        key: 'mobile',
                    },
                    {
                        type: 'input',
                        placeholder: '请输入密码',
                        isPassword: true,
                        key: 'password',
                    }
                ],
                formRules: {
                    mobile: [
                        { validator: validateElePhone, trigger: 'blur' }
                    ],
                    password: [
                        { validator: validateCode, trigger: 'blur', message: '请输入密码' }
                    ]
                },
                formMsg: [
                    {
                        label: '没有账号？',
                        title: '点击注册',
                        callbackName: 'registered'
                    },
                    {
                        title: '忘记密码？',
                        callbackName: 'resetClick'
                    }
                ]
            }
        },
        methods: {
            handleMsg(e) {
                this[e]()
            },
            registered() {
                this.$router.replace({path: '/registered' })
            },
            resetClick() {
                this.$router.replace({ path: '/reset' })
            },
            async onSubmit(e) {
                const result = await this.$postHttp(this.$api.postLogin, e)
                console.log(result)
                if(result.code == 1) {
                    const { token, role_id } = result.data
                    if(role_id == 6) {
                        /**
                         * 移动端
                         * validaMobile
                         */
                        setStorage('token', token)
                        setStorage('isExite', true)
                        this.$store.commit('SET_EXITE_BUT', true)
                        if(validaMobile()) {
                            this.$toast('登录成功');
                            setTimeout(() => {
                                this.$router.replace({
                                    path: '/mobileHome',
                                    query: {
                                        token
                                    }
                                })
                            }, 800);
                            
                        } else {
                            this.$successPrompt('登录成功')
                            setTimeout(() => {
                                this.$router.replace({
                                    path: '/home',
                                    query: {
                                        token
                                    }
                                })
                            }, 800)
                        }
                    } else {
                        validaMobile() ? this.$toast('请使用外部账号登录') : this.$errorPrompt('请使用外部账号登录')
                    }
                }
               
               
            },
            lookMes() {
                window.open('https://beian.miit.gov.cn/')
            }
        }
    }
</script>

<style lang="scss" scoped>
.reset-wrap{
    width: 100%;
    height: 100%;
    background: url('../assets/image/pc_login_bg.png') no-repeat;
    background-size: cover;
    .reset-content{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        ::v-deep .el-form{
            .el-form-item:nth-child(3){
                .el-form-item__content{
                    @include flex();
                }
            }
            .el-form-item:last-child{
                .el-form-item__content{
                    text-align: center;
                }
            }
        }
    }
    .ministry{
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        z-index: 3;
        @include font(12px,#999);
        >div{
            @include flex();
            .lc{
                @include font(16px,#fff);
                background-color: #999;
                border-radius: 50%;
                @include wh(15px,15px);
                margin: 0 5px;
                line-height: 13px;
                text-align: center;
            }
            p{
                margin: 0 5px;
                cursor: pointer;
            }
        }
        
    }
}
@media screen and (max-width:1024px){
    .reset-wrap{
        background: url('../assets/image/mobile/mobile_bg.png') no-repeat;
        background-size: 100% 250px;
        background-position: 0 0;
        overflow: hidden;
        .reset-content{
            width: 100%;
            position: inherit;
            transform: inherit;
            margin-top: 96px;
        }
        .ministry{
            width: 100%;
        }
    }
}
</style>
<style>
@media screen and (max-width: 1024px) {
    .el-message{
        min-width: 300px;
    }
}
</style>